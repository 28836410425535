.section {
  padding: 60px 0 !important;
}

.nav-link {
  cursor: pointer !important;
}

// Maps
.mapouter {
  margin-left: auto;
  margin-right: 8px;
  position: relative;
  text-align: right;
  height: 100;
  width:200;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100;
  width:200;
}

.carousel-item > img {
  // width: 100%; 
  // max-height: 660px; // New addition to avoid hidden images
  // width: auto;
  // margin: auto;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive > iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}